* {
  box-sizing: border-box;
}
*:before {
  box-sizing: border-box;
}
*:after {
  box-sizing: border-box;
}

html {
  display: grid;
  min-height: 100%;
}

html,
body {
  position: relative;
  width: 100%;
  font-weight: 400;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  margin: 0;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  overflow: hidden;
}

ul:not([class]),
ol:not([class]),
ul[class],
ol[class] {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

a {
  text-decoration: none;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}
img:not([alt]) {
  filter: blur(10px);
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
}

.row {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
}
@media screen and (max-width: 700px) {
  .row {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}

.social {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25px;
  text-align: center;
}
.social a {
  color: #fff;
}
@media (width < 1919px) {
  .social a {
    font-size: calc(16px + 6 * (100vw - 320px) / 1600);
    line-height: calc(22px + 6 * (100vw - 320px) / 1600);
  }
}
@media (width >= 1920px) {
  .social a {
    font-size: 22px;
    line-height: 28px;
  }
}
.social a:last-child {
  margin-left: 20px;
}

.profile p:first-child {
  color: inherit;
  font-weight: 300;
  margin-bottom: 50px;
}
@media (width < 1919px) {
  .profile p:first-child {
    font-size: calc(16px + 24 * (100vw - 320px) / 1600);
    line-height: calc(22px + 24 * (100vw - 320px) / 1600);
  }
}
@media (width >= 1920px) {
  .profile p:first-child {
    font-size: 40px;
    line-height: 46px;
  }
}
.profile p:last-child {
  color: inherit;
  font-weight: 400;
}
@media (width < 1919px) {
  .profile p:last-child {
    font-size: calc(24px + 36 * (100vw - 320px) / 1600);
    line-height: calc(30px + 36 * (100vw - 320px) / 1600);
  }
}
@media (width >= 1920px) {
  .profile p:last-child {
    font-size: 60px;
    line-height: 66px;
  }
}

html,
body {
  font-family: "Rajdhani", sans-serif;
  background-color: #234ef3;
  color: #fff;
}

main {
  height: 100%;
  width: 100%;
}

.not-found {
  display: flex;
  color: #fff;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.not-found h1 {
  color: inherit;
}
@media (width < 1919px) {
  .not-found h1 {
    font-size: calc(16px + 24 * (100vw - 320px) / 1600);
    line-height: calc(22px + 24 * (100vw - 320px) / 1600);
  }
}
@media (width >= 1920px) {
  .not-found h1 {
    font-size: 40px;
    line-height: 46px;
  }
}
.not-found h2 {
  color: inherit;
}
@media (width < 1919px) {
  .not-found h2 {
    font-size: calc(24px + 36 * (100vw - 320px) / 1600);
    line-height: calc(30px + 36 * (100vw - 320px) / 1600);
  }
}
@media (width >= 1920px) {
  .not-found h2 {
    font-size: 60px;
    line-height: 66px;
  }
}