* {
  box-sizing: border-box;

  &:before {
    box-sizing: border-box;
  }

  &:after {
    box-sizing: border-box;
  }
}

html {
  display: grid;
  min-height: 100%;
}

html,
body {
  position: relative;
  width: 100%;
  font-weight: 400;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  margin: 0;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  overflow: hidden;
}

ul:not([class]),
ol:not([class]),
ul[class],
ol[class] {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

a {
  text-decoration: none;

  &:not([class]) {
    text-decoration-skip-ink: auto;
  }
}

img {
  max-width: 100%;
  display: block;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;

  &:not([alt]) {
    filter: blur(10px);
  }
}

article {
  > * {
    + {
      * {
        margin-top: 1em;
      }
    }
  }
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
