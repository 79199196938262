.row {
  width: calc(100% - 40%);
  margin-left: 20%;
  margin-right: 20%;

  @media screen and (max-width: 700px) {
    width: calc(100% - 20%);
    margin-left: 10%;
    margin-right: 10%;
  }
}
