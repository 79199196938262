@use "../abstracts/mixins";

.profile {
  p {
    &:first-child {
      color: inherit;
      font-weight: 300;
      margin-bottom: 50px;
      @include mixins.fontSP;
    }

    &:last-child {
      color: inherit;
      font-weight: 400;
      @include mixins.fontBP;
    }
  }
}
