@mixin fontBP() {
  @media (width < 1919px) {
    font-size: calc(24px + (60 - 24) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(30px + (66 - 30) * ((100vw - 320px) / (1920 - 320)));
  }

  @media (width >= 1920px) {
    font-size: 60px;
    line-height: 66px;
  }
}

@mixin fontA() {
  @media (width < 1919px) {
    font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320)));
  }

  @media (width >= 1920px) {
    font-size: 22px;
    line-height: 28px;
  }
}

@mixin fontSP() {
  @media (width < 1919px) {
    font-size: calc(16px + (40 - 16) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(22px + (46 - 22) * ((100vw - 320px) / (1920 - 320)));
  }

  @media (width >= 1920px) {
    font-size: 40px;
    line-height: 46px;
  }
}
