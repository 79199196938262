@use "../abstracts/variables";
@use "../abstracts/mixins";

.social {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25px;
  text-align: center;

  a {
    color: variables.$neutral;
    @include mixins.fontA;

    &:last-child {
      margin-left: 20px;
    }
  }
}
