@use "abstracts/variables";
@use "abstracts/mixins";
@use "base/__base-dir";
@use "components/__components-dir";
@use "layouts/__layouts-dir";

html,
body {
  font-family: "Rajdhani", sans-serif;
  background-color: variables.$primary;
  color: variables.$neutral;
}

main {
  height: 100%;
  width: 100%;
}

.not-found {
  display: flex;
  color: variables.$neutral;
  text-align: center;
  align-items: center;
  justify-content: center;

  h1 {
    color: inherit;
    @include mixins.fontSP;
  }

  h2 {
    color: inherit;
    @include mixins.fontBP;
  }
}
